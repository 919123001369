<template>
    <div>
        <a-card style="margin-bottom:20px">
            <a-form layout="inline">
                <a-form-item class="dep" label="敏感词:">
                    <a-input v-model="searchForm.word" placeholder="请输入敏感词"></a-input>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" icon="search" @click="handleClickSearch">
                        搜索
                    </a-button>
                       
                </a-form-item>
                <a-form-item>
                    <a-button 
                        icon="reload"
                        @click="resetSearchForm">
                        重置
                    </a-button>   
                </a-form-item>
                
            </a-form>
        </a-card>
        <a-card>
            <a-row style="text-align:right;margin-bottom:20px;">
                <a-button type="primary" icon="plus" @click="handleClickAdd">添加</a-button>
                <a-button type="primary" icon="reload" @click="syncWord" style="margin-left:20px" :disabled="syncBtn">同步</a-button>
            </a-row>
             <a-table
                :columns="columns"
                :data-source="tableData"
                :row-key="(record) => record.id"
                :pagination="false"
                :loading="loading"
                >
                <a-tag slot="status" slot-scope="text" :color="text===true?'green':'red'">{{text===true?'启用':'禁用'}}</a-tag>
                <div slot="time" slot-scope="text">
                    {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                </div>
                <a-tag slot="tag" slot-scope="text" color="blue">{{text}}</a-tag>
                <template slot="action"
                slot-scope="text,record">
                    <div class="operation-wrapper">
                        <a @click="handleClickDelete(record)"  style="color:red;">删除</a>
                    </div>  
                </template>
            </a-table>
        </a-card>
        <a-modal v-model="visible" title="新增敏感词" @ok="handleAddSensitive">
            <a-form>
                <a-form-item 
                    label="敏感词" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                        <a-input
                            v-model="addForm.word"
                            :maxLength="30"
                            placeholder="请输入敏感词"
                        />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
const columns = [
     {
        title: '敏感词',
        dataIndex: 'word',
        align:'center',
        scopedSlots: { customRender: 'tag' },
    },
    {
        title:'规则',
        dataIndex:'rule_name',
        align:'center'
    },
    // {
    //     title:'当前状态',
    //     dataIndex:'enable',
    //     align:'center',
    //     scopedSlots: { customRender: 'status' },
    // },
    {
        title:'创建时间',
        dataIndex:'create_time',
        align:'center',
        scopedSlots: { customRender: 'time' },
    },
    {
        title: '操作',
        align:'center',
        width:80,
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
    },
]
import {
    mapState
  } from 'vuex'
export default {
    name:'sensitive',
    data(){
        return{
            searchForm:{
                word:'',
            },
            addForm:{
                word:'',
            },
            loading:false,
            visible:false,
            syncBtn:false,
            columns,
            tableData:[],
        }
    },
    computed: {
        ...mapState(['mqttInit'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleClickSearch()
                }
            },
            immediate:true
        }
    },
    methods:{
        handleClickSearch(){
            this.handleSearch()
        },
        resetSearchForm(){
            this.searchForm = {
                word:'',
                rule_name:''
            }
        },
        handleSearch(){
            this.loading = true  
            let payload = {
                msgId:'searchWord',
                data:{}
            }
            let {word,rule_name} = this.searchForm
            if(word){
                payload.data.word = word
            }
            if(rule_name){
                payload.data.rule_name = rule_name
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchWord",
                payload,
                },
                (topic, data) => {
                    this.loading = false
                    this.tableData = []
                    let val = data.res.map(item=> {
                        return {...item._source}
                    })
                    this.tableData = val
                },
                this
            );
        },
        syncWord(){
            this.syncBtn = true
            setTimeout(()=>{
                this.syncBtn = false
            },10000)
             let payload = {
                msgId:'syncWord',
                data:{
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/weUser/syncWord",
                payload,
                },
                (topic, data) => {
                    this.handleClickSearch()
                },
                this
            );
        },
        handleClickDelete(row){
            let that = this
            this.$confirm({
                title: '',
                content: '您确定要删除此敏感词吗？',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    that.handleDelete(row)
                },
            });
        },
        handleDelete(row){
            let payload = {
                msgId:'delWord',
                data:{
                    rule_id:row.rule_id
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/weUser/delWord",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('删除成功')
                        this.handleClickSearch()
                    }else{
                       this.$message.error(data.message || '删除失败') 
                    }
                },
                this
            );
        },
        handleClickAdd(){
            this.addForm = {
                word:'',
                rule_name:''
            }
            this.visible = true
        },
        handleAddSensitive(){
            if(!this.addForm.word){
                this.$message.error('不能为空')
                return
            }
            let payload = {
                msgId:'addWord',
                data:{
                    rule_name:this.addForm.word,
                    word_list:[this.addForm.word]
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/weUser/addWord",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('新增成功')
                        this.handleClickSearch()
                        this.visible = false
                    }else{
                       this.$message.error(data.message || '新增失败') 
                    }
                },
                this
            );
        },

    }
}
</script>
<style lang="scss" scoped>
.tips{
    font-size: 12px;
    color: chocolate;
    padding:10px 30px;
}
</style>